import * as React from "react";
import { Route, Navigate, Link } from "react-router-dom";

import Stack from "@mui/material/Stack";

import Login from "./views/Login";
import LoginTwoFactor from "./views/LoginTwoFactor";
import Dashboard from "./views/Dashboard";
import Alert from "./views/Alert";
import Camera from "./views/Camera";
import Video from "./views/Video";
import Playback from "./views/Playback";
import Ticket from "./views/Ticket";
import Admin from "./views/Admin";
import Wizard from "./views/Wizard";
import Analytics from "./views/Analytics";
import Restream from "./views/Restream";

import AppPage from "./components/AppPage";
import AdminBar from "./components/AdminBar";

import IconButton from "@mui/material/IconButton";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import GridViewIcon from "@mui/icons-material/GridView";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import EmailIcon from "@mui/icons-material/Email";
import SettingsIcon from "@mui/icons-material/Settings";
import AssessmentIcon from "@mui/icons-material/Assessment";
// import CastIcon from '@mui/icons-material/Cast';
// import HubIcon from '@mui/icons-material/Hub';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

// grafana
import { FaroRoutes } from "@grafana/faro-react";

import { useApp } from "./context/App";

function App() {
    const { client, hasPermission, selectedPage } = useApp();

    const logout = async () => {
        await client.logout();
    };

    // PERMISSION CHECK
    const dashboardRoles = [
        "admin", 
        "editor", 
        "viewer",
        "analytics:viewer",
    ];
    const alertRoles = [
        "admin", 
        "editor", 
        "viewer",
        "camera:viewer",
        "camera:editor",
        "camera:admin",
    ];
    const cameraRoles = [
        "admin",
        "editor",
        "viewer",
        "camera:viewer",
        "camera:editor",
        "camera:admin",
    ];
    const videoRoles = [
        "admin",
        "editor",
        "viewer",
        "video:viewer",
        "video:editor",
        "video:admin",
    ];
    const playbackPlayerRoles = [
        "admin",
        "editor",
        "viewer",
        "player:viewer",
        "player:editor",
        "player:admin",
    ];
    const playbackUserRoles = [
        "admin",
        "editor",
        "viewer",
        "player:viewer",
        "player:editor",
        "player:admin",
    ];
    const analyticsRoles = [
        "admin",
        "editor",
        "viewer",
        "analytics:viewer",
    ];
    const ticketRoles = [];
    const restreamRole = [
        "admin",
        "editor",
        "viewer",
        "restream:viewer",
        "restream:editor",
        "restream:admin",
    ];
    const adminRoles = ["admin"];

    const pagePermission = (pageName) => {
        if (pageName === "dashboard") {
            return hasPermission(dashboardRoles);
        } else if (pageName === "alert") {
            return hasPermission(alertRoles);
        } else if (pageName === "camera") {
            return hasPermission(cameraRoles);
        } else if (pageName === "video") {
            return hasPermission(videoRoles);
        } else if (pageName === "playback_player") {
            return hasPermission(playbackPlayerRoles);
        } else if (pageName === "playback_user") {
            return hasPermission(playbackUserRoles);
        } else if (pageName === "ticket") {
            return hasPermission(ticketRoles);
        } else if (pageName === "restream") {
            return hasPermission(restreamRole);
        } else if (pageName === "admin") {
            return hasPermission(adminRoles);
        } else if (pageName === "analytics") {
            return hasPermission(analyticsRoles);
        } else {
            return false;
        }
    };

    if (!client.LoggedIn()) {
        let content = null;
        if (client.LoggedInTwoFactor()) {
            content = <LoginTwoFactor />;
        } else {
            content = <Login />;
        }

        return (
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                margin={1}
            >
                {content}
            </Stack>
        );
    }

    if (client.UserSetup()) {
        return (
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                margin={1}
            >
                <Wizard />;
            </Stack>
        );
    }

    let pageMenu = (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
        >
            <IconButton
                aria-label="dashboard"
                disabled={!pagePermission("dashboard")}
                size="large"
                component={Link}
                to="/dashboard"
                style={{
                    backgroundColor:
                        selectedPage === "dashboard"
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                }}
            >
                <GridViewIcon
                    fontSize="inherit"
                    color={pagePermission("dashboard") ? "blue" : "disabled"}
                />
            </IconButton>
            <IconButton
                aria-label="camera"
                disabled={!pagePermission("camera")}
                size="large"
                component={Link}
                to="/camera"
                style={{
                    backgroundColor:
                        selectedPage === "camera"
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                }}
            >
                <VideocamIcon
                    fontSize="inherit"
                    color={pagePermission("camera") ? "blue" : "disabled"}
                />
            </IconButton>
            <IconButton
                aria-label="restream"
                disabled={!pagePermission("restream")}
                size="large"
                component={Link}
                to="/restream"
                style={{
                    backgroundColor:
                        selectedPage === "restream"
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                }}
            >
                <MissedVideoCallIcon
                    fontSize="inherit"
                    color={pagePermission("restream") ? "blue" : "disabled"}
                />
            </IconButton>
            <IconButton
                aria-label="video"
                disabled={!pagePermission("video")}
                size="large"
                component={Link}
                to="/video"
                style={{
                    backgroundColor:
                        selectedPage === "video"
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                }}
            >
                <VideoFileIcon
                    fontSize="inherit"
                    color={pagePermission("video") ? "blue" : "disabled"}
                />
            </IconButton>
            <IconButton
                aria-label="playback"
                disabled={
                    !pagePermission("playback_player") &&
                    !pagePermission("playback_user")
                }
                size="large"
                component={Link}
                to="/playback/player"
                style={{
                    backgroundColor:
                        selectedPage === "playback"
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                }}
            >
                <PlayCircleIcon
                    fontSize="inherit"
                    color={
                        !pagePermission("playback_player") &&
                        !pagePermission("playback_user")
                            ? "disabled"
                            : "blue"
                    }
                />
            </IconButton>
            {/* <IconButton
                aria-label="ticket"
                disabled={!pagePermission("ticket")}
                size="large"
                component={Link}
                to="/ticket"
                style={{
                    backgroundColor:
                        selectedPage === "ticket"
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                }}
            >
                <EmailIcon
                    fontSize="inherit"
                    color={pagePermission("ticket") ? "blue" : "disabled"}
                />
            </IconButton> */}
            <IconButton
                aria-label="analytics"
                disabled={!pagePermission("analytics")}
                size="large"
                component={Link}
                to="/analytics"
                style={{
                    backgroundColor:
                        selectedPage === "analytics"
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                }}
            >
                <AssessmentIcon 
                    fontSize="inherit" 
                    color={pagePermission("analytics") ? "blue" : "disabled"}
                />
            </IconButton>
            <IconButton
                aria-label="alert"
                disabled={!pagePermission("alert")}
                size="large"
                component={Link}
                to="/alert"
                style={{
                    backgroundColor:
                        selectedPage === "alert"
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                }}
            >
                <ReportProblemIcon
                    fontSize="inherit"
                    color={pagePermission("alert") ? "blue" : "disabled"}
                />
            </IconButton>
            <IconButton
                aria-label="admin"
                disabled={!pagePermission("admin")}
                size="large"
                component={Link}
                to="/admin"
                style={{
                    backgroundColor:
                        selectedPage === "admin"
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                }}
            >
                <SettingsIcon
                    fontSize="inherit"
                    color={pagePermission("admin") ? "blue" : "disabled"}
                />
            </IconButton>
        </Stack>
    );

    let userMenu = (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
        >
            <IconButton aria-label="logout" size="large" onClick={logout}>
                <ExitToAppIcon
                    fontSize="inherit"
                    style={{ color: "#494747" }}
                />
            </IconButton>
        </Stack>
    );

    let content = (
        <AppPage pageMenu={pageMenu} userMenu={userMenu}>
            <FaroRoutes>
                {pagePermission("dashboard") && (
                    <React.Fragment>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                    </React.Fragment>
                )}
                {pagePermission("camera") && (
                    <React.Fragment>
                        <Route path="/" element={<Camera />} />
                        <Route path="/camera" element={<Camera />} />
                        <Route path="/camera/:camera_id" element={<Camera />} />
                    </React.Fragment>
                )}
                {pagePermission("restream") && (
                    <React.Fragment>
                        <Route path="/" element={<Restream />} />
                        <Route path="/restream" element={<Restream />} />
                        <Route path="/restream/:restream_id" element={<Restream />} />
                    </React.Fragment>
                )}
                {pagePermission("video") && (
                    <React.Fragment>
                        <Route path="/video" element={<Video />} />
                        <Route path="/video/:video_id" element={<Video />} />
                    </React.Fragment>
                )}
                {pagePermission("playback_player") && (
                    <React.Fragment>
                        <Route path="/playback/player" element={<Playback />} />
                        <Route
                            path="/playback/player/:player_id"
                            element={<Playback />}
                        />
                    </React.Fragment>
                )}
                {pagePermission("playback_user") && (
                    <React.Fragment>
                        <Route path="/playback/user" element={<Playback />} />
                        <Route
                            path="/playback/user/:user_id"
                            element={<Playback />}
                        />
                    </React.Fragment>
                )}
                {pagePermission("ticket") && (
                    <React.Fragment>
                        <Route path="/ticket" element={<Ticket />} />
                        <Route path="/ticket/:ticket_id" element={<Ticket />} />
                    </React.Fragment>
                )}
                {pagePermission("analytics") && (
                    <React.Fragment>
                        <Route path="/analytics" element={<Analytics />} />
                        <Route path="/admin" element={<Admin />} />
                    </React.Fragment>
                )}
                {pagePermission("alert") && (
                    <Route path="/alert" element={<Alert />} />
                )}
                <Route path="/*" element={<Navigate to="/" replace />} />
            </FaroRoutes>
        </AppPage>
    );

    const user = client.UserConfig();

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
            margin={1}
        >
            {user.type === "superuser" && <AdminBar />}
            {content}
        </Stack>
    );
}

App.propTypes = {};

export default App;
