import * as React from "react";

import "moment-timezone";
import moment from "moment";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FilterListIcon from '@mui/icons-material/FilterList';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import SupportIcon from "@mui/icons-material/Support";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

import SlaFilterSelect from "./slaFilterSelect";

import fallback from "../../../images/fallback.png";

function GetRows({ alerts, cameras }) {
    let rows = [];

    for (const a in alerts) {
        if (alerts[a].item_type === "camera") {
            const camera = cameras.find(row => row["id"] === alerts[a].item_id);
            if (camera) {
                rows = rows.concat([
                    {
                        "id": alerts[a].item_id,
                        "alias_id": camera.alias_id ? camera.alias_id : '',
                        "name": camera.meta.name,
                        "sla": camera.sla,
                        "status": alerts[a].status,
                        "status_message": alerts[a].status_message,
                        "created_at": alerts[a].created_at,
                    }
                ]);
            }
        }
    }

    return rows;
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Name",
    },
    {
        id: "alias_id",
        numeric: false,
        disablePadding: false,
        label: "Alias ID",
    },
    {
        id: "sla",
        numeric: false,
        disablePadding: false,
        label: "SLA",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
    },
    {
        id: "created_at",
        numeric: true,
        disablePadding: false,
        label: "Erstellt",
    },
];


function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function CameraImage({ id, playerConfig }) {
    const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

    React.useEffect(() => {
        const img = new Image();
        img.src = `${playerConfig.url}${id}/live/main_240.jpg`;
        img.onload = () => {
            setBackgroundImage(`url(${img.src})`);
        };
    }, []);

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundImage: backgroundImage,
                backgroundSize: "cover",
                padding: 0,
                height: 30,
                minWidth: 55,
                borderRadius: 1,
                border: "1px solid #f1f1f1",
            }}
        />
    );
}


EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable({ alerts, camera, cameras, setCamera, playerConfig }) {
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("created_at");

    const page = 0;
    const rowsPerPage = 1000;
    const rows = GetRows({ alerts, cameras });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const [searchText, setSearchText] = React.useState("");

    const handleChangeSearch = (event) => {
        setSearchText(event.target.value);
    };

    const filteredRows = React.useMemo(() => {
        const lowerSearchText = searchText.toLowerCase();
        return rows.filter(
            (row) =>
                row.alias_id.toLowerCase().includes(lowerSearchText) ||
                row.name.toString().toLowerCase().includes(lowerSearchText),
        );
    }, [rows, searchText]);

    const visibleRows = React.useMemo(
        () =>
            stableSort(filteredRows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [filteredRows, order, orderBy, page, rowsPerPage],
    );

    const handleClick = (event, id) => {
        const camera = cameras.find(row => row["id"] === id);
        setCamera(camera);
    };

    const isSelected = (id) => camera ? camera.id === id : false;

    const [slaFilter, setSlaFilter] = React.useState(() => ['2', '8']);

    const filteredAlerts = () => {
        let count = 0;
        rows.filter((row) => {
            if (row.sla && slaFilter.includes(row.sla) || !row.sla && slaFilter.includes('null')) {
                count += 1;
            }
        });
        return count;
    };

    function transAlertStatus(status) {
        if (status === "open") {
            return "Offen";
        } else if (status === "closed") {
            return "Geschlossen";
        } else if (status === "in_progress") {
            return "In Arbeit";
        } else if (status === "waiting") {
            return "Wartend";
        } else {
            return status;
        }
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "flex-start", sm: "space-between" }}
                alignItems="flex-start"
                spacing={2}
                minHeight={75}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-left"
                    alignItems="flex-left"
                    spacing={2}
                    maxWidth={550}
                    padding={"0px 25px 0px 25px"}
                    fontSize={24}
                >
                    {filteredAlerts()} / {rows.length} Meldungen
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-left"
                    alignItems="center"
                    spacing={2}
                >
                    <Paper sx={{
                        //borderRadius: 2,
                        padding: "1em 0em 1em 0em",
                        //width: 350,
                        minWidth: { xs: 50, md: 308 },
                        display: "flex",
                        alignItems: "center",
                        height: 40,
                    }}>
                        <SlaFilterSelect slaFilter={slaFilter} setSlaFilter={setSlaFilter} />
                        <FilterListIcon style={{color: '#B3B3B3'}}/>
                    </Paper>
                    <Paper sx={{
                        //borderRadius: 2,
                        padding: "1em 0em 1em 0em",
                        width: 350,
                        minWidth: { xs: 50, md: 250 },
                        display: "flex",
                        alignItems: "center",
                        height: 40,
                    }}>
                        <TextField
                            //label="Suche"
                            placeholder="Suche"
                            variant="outlined"
                            value={searchText}
                            onChange={handleChangeSearch}
                            fullWidth
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Paper>
                </Stack>
            </Stack>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const labelId = `enhanced-table-${index}`;
                                const isItemSelected = isSelected(row.id);
                                if (row.sla && slaFilter.includes(row.sla) || !row.sla && slaFilter.includes('null')) {
                                    return (
                                        <TableRow
                                            hover
                                            role="row"
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{ cursor: "pointer" }}
                                            onClick={(event) => handleClick(event, row.id)}
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="normal"
                                            >
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <CameraImage
                                                        id={row.id}
                                                        playerConfig={playerConfig}
                                                    />
                                                    <span>{row.name}</span>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                {row.alias_id}
                                            </TableCell>
                                            <TableCell>
                                                {row.sla && (
                                                    <Chip
                                                        size="small"
                                                        //icon={<SupportIcon style={{ color: "#fff" }} />}
                                                        style={{
                                                            color: "#fff",
                                                            backgroundColor: "#0085E9",
                                                            padding: 0,
                                                        }}
                                                        label={`SLA ${row.sla === "0" ? "" : row.sla}`}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Chip
                                                    size="small"
                                                    //icon={<SupportIcon style={{ color: "#fff" }} />}
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: "#0085e9",
                                                        padding: 0,
                                                    }}
                                                    label={transAlertStatus(row.status ? row.status : "open")}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                {moment
                                                    .utc(row.created_at)
                                                    .local()
                                                    .format("DD.MM.YYYY HH:mm:ss")
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}
