import { CSVLink } from "react-csv";
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

function AnalyticsCsvButton(rows, apiQuery) {
    if (!rows || rows.length === 0) {
        return null;
    }
    const from_date = new Date(apiQuery.from_date);
    const to_date = new Date(apiQuery.to_date);
    const csvData = [
        ["name", "session_hits", "session_seconds", "session_seconds_avg"] // "session_parallel"
    ];
    rows.rows.forEach((line) => {
        csvData.push([line.name, line.session_hits, line.session_seconds, line.session_seconds_avg]); //line.session_parallel]);
    });
    return (
        <CSVLink data={csvData} filename={`cm-analytics-${apiQuery.from_date}-${apiQuery.to_date}.csv`}>
            <IconButton aria-label="csv-download" sx={{color: "#0085e9"}}>
                <DownloadForOfflineIcon />
            </IconButton>
        </CSVLink>
    );
};


export default AnalyticsCsvButton;