import * as React from 'react';
import PropTypes from 'prop-types';

import 'moment-timezone';
import moment from 'moment';
import 'moment/locale/de';

import Box from '@mui/material/Box';
import Chart from 'react-apexcharts';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';


function ChartRangeSelecter({ disabled, statsRange, setStatsRange }) {
	const handleChange = (event) => {
		setStatsRange(event.target.value ? event.target.value : null);
	};

	return (
		<TextField
			select
			size="small"
			id="chart-series-select"
			value={statsRange}
			label={null}
			onChange={handleChange}
			color={'secondary'}
			disabled={disabled}
			variant="outlined"
			sx={{
				'& .MuiSelect-select': {
					padding: '4px 8px',
					fontSize: '0.95rem',
				},
			}}
		>
			<MenuItem value="30">30 Minuten</MenuItem>
			<MenuItem value="60">1 Stunde</MenuItem>
			<MenuItem value="720">12 Stunden</MenuItem>
			<MenuItem value="1440">24 Stunden</MenuItem>
		</TextField>
	);
}


function ChartSeriesSelecter({ disabled, statsMetric, setStatsMetric }) {
	const handleChange = (event) => {
		setStatsMetric(event.target.value ? event.target.value : null);
	};

	return (
		<TextField
			select
			size="small"
			id="chart-series-select"
			value={statsMetric}
			label={null}
			onChange={handleChange}
			color={'secondary'}
			disabled={disabled}
			variant="outlined"
			sx={{
				'& .MuiSelect-select': {
					padding: '4px 8px',
					fontSize: '0.95rem',
				},
			}}
		>
			<MenuItem value="fps">FPS</MenuItem>
			<MenuItem value="bitrate_kbits">Bitrate</MenuItem>
		</TextField>
	);
}

function CameraStreamStats({ camera, stats, statsRange, setStatsRange, statsMetric, setStatsMetric }) {
	const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	const annotationData = (data) => {
		if (!data || data.length === 0) {
			return null;
		}

		let result = [];
		let period = {};

		data.forEach((item, index) => {
			if (item[1] === '0') {
				if (!period.x) {
					period.x = item[0];
				}
			} else {
				if (period.x) {
					period.x2 = data[index - 1][0];
					period.fillColor = 'rgba(193,39,45,.9)';
					period.label = { text: 'Störung', borderColor: 'rgba(193,39,45,.9)', style: { background: 'rgba(193,39,45,.9)', color: '#fff' } };
					result.push(period);
					period = {};
				}
			}
		});

		if (period.x && !period.x2) {
			period.x2 = data[data.length - 1][0];
			period.fillColor = 'rgba(193,39,45,.9)';
			period.label = { text: 'Störung', borderColor: 'rgba(193,39,45,.9)', style: { background: 'rgba(193,39,45,.9)', color: '#fff' } };
			result.push(period);
		}

		return result;
	};

	const { series, status_series } = React.useMemo(() => {
		let series = [];
		let status_series = stats.status ? stats.status : [];
		let profile_series = {};

		stats.streams.forEach((stream) => {
			try {
				if (!profile_series[stream.profile]) {
					profile_series[stream.profile] = {
						data: stream[statsMetric],
						name: stream.resolution,
					};
				}
			} catch (e) {
				console.log(e);
			}
		});

		for (const [key, value] of Object.entries(profile_series)) {
			series.push(value);
		}

		return { series, status_series };
	}, [stats, statsMetric]);

	const annotations = React.useMemo(() => annotationData(status_series), [status_series]);

	let sla = camera.sla;
    if (sla) {
        sla = true;
    };

	const graphCondition = ({sla, series}) => {
        if (sla && series && series.length > 0) {
            return {sla, series};
        }
        return null;
    }

	const useGraphCondition = React.useMemo(() => {
        return graphCondition({sla, series});
    }, [sla, series]);

	return (
		<Box
			elevation={2}
			sx={{
				width: '100%',
				borderRadius: 1,
				border: '2px solid rgba(0,0,0,.035)',
			}}
			marginBottom={1.5}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="flex-start"
				spacing={0}
				paddingTop={1}
				paddingLeft={2}
				paddingRight={1}
				marginBottom={0}
			>
				<Typography variant="h6" marginTop={0.5}>
					Monitor
				</Typography>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
					spacing={1}
				>
					<ChartSeriesSelecter disabled={!useGraphCondition} statsMetric={statsMetric} setStatsMetric={setStatsMetric} />
					<ChartRangeSelecter  statsRange={statsRange} setStatsRange={setStatsRange} />
				</Stack>
			</Stack>
			{useGraphCondition && (
				<>
					<style>
						{`
                            .apexcharts-tooltip-text {
                                margin: 0;
                                padding: 0px 0;
                                line-height: 6px;
                            }
                            .apexcharts-tooltip {
                                transform: translate(0%, -100%) !important;
                            }
                        `}
					</style>
					<Chart
						options={{
							chart: {
								id: 'analytics-chart',
								toolbar: {
									show: false,
								},
								animations: {
									enabled: false,
								},
								zoom: {
									enabled: false,
								},
								width: '100%',
							},
							noData: {
								text: 'Keine Daten verfügbar',
								offsetY: -35,
								style: {
									fontSize: '18px',
								},
							},
							xaxis: {
								type: 'datetime',
								labels: {
									style: {
										colors: [],
										fontSize: '12px',
										fontFamily: 'Helvetica, Arial, sans-serif',
										fontWeight: 400,
										cssClass: 'apexcharts-xaxis-label',
									},
									formatter: function (value, timestamp) {
										return `${moment.unix(timestamp).format("HH:mm")}:00`;
									}, 
									hideOverlappingLabels: true,
									rotateAlways: false,
									rotate: 0,
								},
								tooltip: {
									enabled: false,
									formatter: function (value) {
										return value.toLocaleString('de-DE');
									},
								},
							},
							yaxis: {
								labels: {
									formatter: function (value) {
										return value.toLocaleString('de-DE');
									},
								},
								tooltip: {
									enabled: false,
									formatter: function (value) {
										return value.toLocaleString('de-DE');
									},
									title: {
										formatter: (seriesName) => seriesName,
									},
								},
							},
							stroke: {
								curve: 'straight',
								width: 2.5,
							},
							legend: {
								show: false,
							},
							tooltip: {
								hideEmptySeries: false,
								x: {
									format: 'HH:mm:ss',
								},
								y: {
									formatter: function (value) {
										return value.toLocaleString('de-DE');
									},
								},
							},
							annotations: {
								xaxis: annotations,
							},
						}}
						series={series}
						type="line"
						height={175}
						width={'100%'}
					/>
				</>
			)}
			{!useGraphCondition && (
				<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} paddingTop={2} paddingBottom={7}>
					<Typography variant="h6" fontWeight={400}>
                        {sla ? "Keine Verbindungsdaten verfügbar." : "Nicht verfügbar."}
                    </Typography>
				</Stack>
			)}
		</Box>
	);
}

CameraStreamStats.propTypes = {
	camera: PropTypes.object.isRequired,
	stats: PropTypes.object.isRequired,
	statsRange: PropTypes.string.isRequired, 
	setStatsRange: PropTypes.func.isRequired,
	statsMetric: PropTypes.string.isRequired, 
	setStatsMetric: PropTypes.func.isRequired,
};

CameraStreamStats.defaultProps = {
	camera: {},
	stats: {},
	statsRange: "30",
	setStatsRange: () => {},
	statsMetric: "bitrate_kbits",
	setStatsMetric: () => {},
};

export default CameraStreamStats;
